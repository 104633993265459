import React from 'react';
import {PanelMenu} from 'primereact/panelmenu';

import './HomePageSectionOne.scss';

const HomePageSectionOne = () => {
  const content = {
    SEC_1_H_RSI: 'Real Estate Intelligent.',
    SEC_1_PARAGRAPH_1: 'DCH MIAMI is making space for a new generation of homebuyers. Collaborative ' +
      'and customer-obsessed, we are a multidisciplinary real estate sales and marketing agency striving ' +
      'to accelerate project reach for our clients and transform homeownership for individuals. We ' +
      'believe the future of real estate is fundamentally human. With relationships at our foundation, ' +
      'our team creates sales platforms of influence for boutique developments to mixed-use masterplan ' +
      'communities. In shaping the physical environment of our cities, we are passionate about more than ' +
      'the skyline. We’re building storied neighbourhoods and connecting to homebuyers in a more ' +
      'meaningful way.',
    SEC_1_PARAGRAPH_2: 'DCH MIAMI is committed to helping end homelessness by raising funds to build 3D printed ' +
      'communities in the world together with the World Housing Organization.',
    SEC_1_H_CS: 'Core services',
  };

  const items = [
    {
      label: 'Advisory',
      items: [
        {label: 'MARKET INTELLIGENCE'},
        {label: 'PRODUCT DESIGN'},
        {label: 'PRICE ANALYSIS'}
      ]
    },
    {
      label: 'Project Marketing',
      items: [
        {label: 'BRANDING & POSITIONING'},
        {label: 'LAUNCH STRATEGY'},
        {label: 'MARKETING EXECUTION'}
      ]
    },
    {
      label: 'Communications',
      items: [
        {label: 'MEDIA PLANNING & BUYING'},
        {label: 'EVENT PLANNING'},
      ]
    },
    {
      label: 'Sales',
      items: [
        {label: 'SALES STRATEGY'},
        {label: 'SALES TRAINING'},
        {label: 'SALES MANAGEMENT'}
      ]
    },
    {
      label: 'Lease Up',
      items: [
        {label: 'ANALYTICS & PRODUCT DEVELOPMENT'},
        {label: 'MARKETING STRATEGY & EXECUTION'},
        {label: 'LEASE-UP STRATEGY & MANAGEMENT'}
      ]
    },
    {
      label: 'Customer Care',
      items: [
        {label: 'CONTRACT MANAGEMENT'},
        {label: 'CUSTOMER COMMUNICATION'},
        {label: 'COMPLETION SERVICES'}
      ]
    },
  ];
  return (
    <section className="home-page-section-one">
      <div className="container hp-grid">
        <div className="hp-paragraph">
          <h2>{content.SEC_1_H_RSI}</h2>
          <div className="text-columns">
            <p>{content.SEC_1_PARAGRAPH_1}</p>
            <p>{content.SEC_1_PARAGRAPH_2}</p>
          </div>
        </div>
        <div className="hp-services">
          <h2>{content.SEC_1_H_CS}</h2>
          <PanelMenu className="hp-services-panel-menu" model={items} style={{width: '22rem'}}/>
        </div>
      </div>
    </section>
  );
};

export default HomePageSectionOne;