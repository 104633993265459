import React from 'react';
import AppConst from '../../../App.const';

import './CoreServicesPageBanner.scss';

const CoreServicesPageBanner = () => {
  const content = {
    HP_CSP_BANNER_HEADING: 'A Comprehensive Platform of Possibilities',
    HP_CSP_BANNER_CAPTION: 'THE RIGHT EYES ON YOUR PROJECT. OUR EYES FIXED FORWARD.',
  }

  return (
    <section className="core-services-page-banner">
      <div className="csp-banner">
        <div className="csp-banner-content">
          <h2 className="csp-banner-heading">{content.HP_CSP_BANNER_HEADING}</h2>
          <h6 className="csp-banner-caption">{content.HP_CSP_BANNER_CAPTION}</h6>
        </div>
        <img className="csp-banner-image" src={AppConst.CORE_SERVICES_PAGE_BANNER_IMG_SRC} alt="image not loaded"/>
      </div>
    </section>
  );
};

export default CoreServicesPageBanner;