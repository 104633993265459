import React, {useState} from 'react';

import './App.css';

import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css";
import PagesRouter from './Pages/PagesRouter';
import {withTranslation} from "react-i18next";
import LanguagePicker from './LanguagePicker/LanguagePicker';

function App(props: any) {
  const [lang, setLang] = useState('en');

  const onLanguageChange = (newLang: string) => {
    setLang(newLang);
    window.localStorage.setItem('user_language', newLang);
    props.i18n.changeLanguage(newLang);
  };

  return (
    <>
      <PagesRouter/>
      <LanguagePicker onChange={(lang: string) => onLanguageChange(lang)}/>
    </>
  );
}

export default withTranslation()(App);
