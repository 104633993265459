import React, {Component} from 'react';

class AppConst extends Component {
  static PATH_HOME = 'home';
  static PATH_PROJECTS = 'projects';
  static PATH_CORE_SERVICES = 'core_services';
  static PATH_DCH_MIAMI_VISION = 'dch_miami_vision';
  static PATH_NEWS_FEED = 'news_feed';
  static PATH_CONTACT = 'contact';
  static PATH_CAREERS = 'careers';

  static SUB_PATH_PROJECTS_PROJECT = 'project';

  static PROJECT_SELECTOR_KEY_NAME: string = "id";

  static LANG_CODE_EN: string = 'en';
  static LANG_CODE_ES: string = 'es';
  static LANG_CODE_PT: string = 'pt';
  static LANG_CODE_PL: string = 'pl';
  static LANG_CODE_DE: string = 'de';
  static LANG_CODE_FR: string = 'fr';
  static LANG_CODE_IT: string = 'it';
  static LANG_CODE_PT_BR: string = 'pt_br';
  static LANG_NAME_EN: string = 'english';
  static LANG_NAME_ES: string = 'es';
  static LANG_NAME_PT: string = 'pt';
  static LANG_NAME_PL: string = 'pl';
  static LANG_NAME_DE: string = 'de';
  static LANG_NAME_FR: string = 'fr';
  static LANG_NAME_IT: string = 'it';
  static LANG_NAME_PT_BR: string = 'pt_br';

  static DATA_PATH_PROJECT_VIEW: string = '/data/projects/projectView';
  static DATA_PATH_PROJECTS: string = '/data/projects';

  static FILE_NAME_PROJECTS_DATA_FILE: string = 'projects';

  static ERROR_IMAGE_SRC: string = '/asserts/images/error-image.svg';
  static HOME_HERO_SECTION_VID_SRC: string = 'https://st.depositphotos.com/34420908/56903/v/600/depositphotos_569039616-stock-video-aerial-footage-of-luxury-apartment.mp4';
  static HOME_BANNER_SECTION_ONE_IMG_SRC: string = 'https://res.cloudinary.com/dahosa0zw/image/upload/v1665021425/dch/lux_cu2xsk.png';
  static BLACK_LOGO_IMG_SRC: string = '/asserts/images/logo.svg';
  static WHITE_LOGO_IMG_SRC: string = '/asserts/images/logo-W.svg';
  static HOME_FD_SECTION_BANNER_IMG_SRC: string = 'https://res.cloudinary.com/dahosa0zw/image/upload/v1665021540/dch/rsm_rk5gd4.png';
  static CORE_SERVICES_PAGE_BANNER_IMG_SRC: string = 'https://mlacanada.com/sites/default/files/styles/home_featured_project/public/2017-05/GGeoffrey%20DSC_2415_HighRes_BW.jpg?itok=xOmEULun';
  static QUOTE_ICON_IMG_SRC: string = '/asserts/images/icons/quote-svgrepo-com.svg';

  static STYLE_NO_DEC = {textDecoration: 'none'};
  static CORE_SERVICES_BANNER_IMG_SRC: string = 'https://mlacanada.com/sites/default/files/styles/home_featured_project/public/2017-05/GGeoffrey%20DSC_2415_HighRes_BW.jpg?itok=xOmEULun';
  static MIN_DESKTOP_WIDTH: number = 900;
}

export default AppConst;