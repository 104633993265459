import React from 'react';

import './VisionPage.scss';
import PersonCard from '../../Components/PersonCard/PersonCard';
import HomePageBannerSection from '../HomePage/HomePageBannerSection/HomePageBannerSection';

const VisionPage = () => {
  const content = {
    VP_STATEMENT_SECTION_HEADING: 'Together, we RISE above.',
    VP_STATEMENT_SECTION_STATEMENT_CONTENT: [
      'Our company\'s foundation is built on the four pillars of Relationships, Insights, Sales, and Experience. We believe in guiding the inches of innovation through thoughtful leadership to best serve our development partners, realtors, and homebuyers while achieving the vision of creating meaningful real estate experiences.',
      'It is our belief that the commitment to RISE is what drives our collaborative expertise with our clients and partners alike. So much of what we do at MLA Canada is based on understanding consumer interests and how those interests apply to the marketplace we are operating within today. We have made it a top priority to thoroughly understand stakeholder wants and needs and make better recommendations through actionable intelligence.',
      '“RISE is more valuable and applicable today than ever. It is how we draw insights out, leverage our expertise, and propel sales forward – making a significant impact on how we maintain the incredible relationships we care so deeply for.”',
      '– Ryan Lalonde, President of MLA Canada',
    ],
    VP_LEADERSHIP_TEAM_HEADING: 'leadership team',
    VP_LEADERSHIP_TEAM: [
      {name: 'CAMERON MCNEILL', designation: 'EXECUTIVE DIRECTOR, PARTNER', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
      {name: 'RYAN LALONDE', designation: 'PRESIDENT, PARTNER', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
      {name: 'SHAYNA MACQUISTEN', designation: 'CHIEF REVENUE OFFICER, PARTNER', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
      {name: 'SUZANA GONCALVES', designation: 'EVP SALES AND MARKETING, PARTNER', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
      {name: 'BRITTANY REIMER', designation: 'MANAGING DIRECTOR, FRASER VALLEY', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
      {name: 'LISA FEIST', designation: 'CHIEF OPERATING OFFICER', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
      {name: 'ASHLEY JUDD', designation: 'VICE PRESIDENT, SALES OPERATIONS', picture: 'https://mlacanada.com/sites/default/files/styles/large/public/profile-pics/MLA_Web_TeamPhotos_Tiles_Ashley_0.jpg?itok=i1QBDhJm'},
    ],
  }

  return (
    <>
      <section className="vp-statement-section">
        <h2 className="vp-statement-heading">{content.VP_STATEMENT_SECTION_HEADING}</h2>
        {
          content.VP_STATEMENT_SECTION_STATEMENT_CONTENT.map((data, index) => (
            <p className="vp-statement-statement" key={index}>{data}</p>
          ))
        }
      </section>
      <section className="vp-leadership-section">
        <h2 className="section-heading">{content.VP_LEADERSHIP_TEAM_HEADING}</h2>
        <div className="vp-leadership-team-grid">
          {
            content.VP_LEADERSHIP_TEAM.map((data, index) => (
              <PersonCard data={data} key={index}/>
            ))
          }
        </div>
      </section>
      <HomePageBannerSection/>
    </>
  );
};

export default VisionPage;