import React from 'react';
import Banner from '../../../Components/Banner/Banner';
import AppConst from '../../../App.const';
import {Button} from 'primereact/button';

import './HomePageBannerSection.scss';

const HomePageBannerSection = () => {
  const content = {
    HP_BANNER_HEADING: 'DCH MIAMI NEWSWIRE REAL ESTATE INTELLIGENT',
    HP_BANNER_PARAGRAPH: 'Subscribe to the DCH MIAMI Newswire for a daily email round-up on the latest local real ' +
      'estate industry insights, news, home buying architecture, design, and cultural trends.',
    HP_SUBSCRIBE_NOW: 'Subscribe now',
  };

  return (
    <section className="home-page-banner-section">
      <div className="container">
        <Banner img={AppConst.HOME_BANNER_SECTION_ONE_IMG_SRC}>
          <div className="hp-banner-content">
            <div className="grid-column">
              <h2>{content.HP_BANNER_HEADING}</h2>
              <p>{content.HP_BANNER_PARAGRAPH}</p>
            </div>
            <div className="flex-center">
              <img className="logo" src={AppConst.WHITE_LOGO_IMG_SRC} alt="logo"/>
              <Button label={content.HP_SUBSCRIBE_NOW}/>
            </div>
          </div>
        </Banner>
      </div>
    </section>
  );
};

export default HomePageBannerSection;