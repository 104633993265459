import React from 'react';

import AppConst from '../../../App.const';
import Banner from '../../../Components/Banner/Banner';

import './HomePageFeaturedHeadlineSection.scss';

const HomePageFeaturedHeadlineSection = () => {

  const content = {
    HP_FEATURED_HEADLINE_HEADING: 'Featured Headline',
    HP_NEWS_HEADER: '2022 Pre-Sale Pulse',
    HP_NEWS_PARAGRAPH: '2022 Pre-Sale Real Estate Market Insights THIS EPISODES IS NOW AVAILABLE IN MY YOUTUBE ' +
      'CHANNEL DCHRE',
    HP_NEWS_PODCAST_DATE: '',
    HP_NEWS_PODCAST_NAME: 'PRE-SALE PULSE',
    HP_NEWS_PODCAST_PLACE: 'MIAMI DADE',
    HP_NEWS_PODCAST_PRESENTED_BY: 'PRESENTED BY',
    HP_NEWS_PODCAST_HOST: 'DAVID CHAVARRIA & DALIA CHAVARRIA',
  };

  return (
    <section className="home-page-featured-headline-section">
      <div className="container">
        <h2 className="section-heading">{content.HP_FEATURED_HEADLINE_HEADING}</h2>
        <div className="news-grid">
          <div>
            <h3 className="news-header">{content.HP_NEWS_HEADER}</h3>
            <p>{content.HP_NEWS_PARAGRAPH}</p>
          </div>
          <div>
            <Banner img={AppConst.HOME_FD_SECTION_BANNER_IMG_SRC}>
              <div className="flex-center color-white banner-content">
                <img className="logo" src={AppConst.WHITE_LOGO_IMG_SRC} alt="logo"/>
                <label className="hp-fh-sec-banner-date">{content.HP_NEWS_PODCAST_DATE}</label>
                <label className="hp-fh-sec-banner-name">{content.HP_NEWS_PODCAST_NAME}</label>
                <label className="hp-fh-sec-banner-place">{content.HP_NEWS_PODCAST_PLACE}</label>
                <label className="hp-fh-sec-banner-pb">{content.HP_NEWS_PODCAST_PRESENTED_BY}</label>
                <label className="hp-fh-sec-banner-host">{content.HP_NEWS_PODCAST_HOST}</label>
              </div>
            </Banner>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomePageFeaturedHeadlineSection;