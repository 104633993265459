import React from 'react';

import './CurrentProjectsSection.scss';
import ProjectCard from '../../../Components/ProjectCard/ProjectCard';
import FilterTabsComponent from '../FilterTabsComponent/FilterTabs.Component';

const CurrentProjectsSection = () => {

  const content = {
    PP_FPS_HEADING: 'current projects',
    PP_FPS_CARDS_CONTENT: [
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
    ],
  }

  return (
    <section className="current-projects-section">
      <div className="container">
        <h2 className="heading-three-sub">{content.PP_FPS_HEADING}</h2>
        <FilterTabsComponent filterTabs={[
          {
            title: 'Status',
            filtersList: ['Register Now', 'Coming Soon', 'Renting Now', 'Now Selling',]
          },
          {
            title: 'City',
            filtersList: ['West Vancouver', 'Penticton', 'Richmond', 'Vancouver', 'Surrey', 'Vancouver', 'Vancouver West',]
          },
          {
            title: 'Building type',
            filtersList: ['Condos', 'Condos & Townhomes', 'Garden Homes & Townhomes', 'Townhomes', 'Condos + townhomes', 'Condos & Loft Homes',]
          },
          {
            title: 'Product type',
            filtersList: ['Residential', 'Condos',]
          },
        ]}/>
        <div className="cp-cards-grid">
          {content.PP_FPS_CARDS_CONTENT.map((data, index) => (
            <ProjectCard renderData={data} key={index}/>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CurrentProjectsSection;