import React from 'react';

import './PersonCard.scss';

const PersonCard = (props: any) => {
  return (
    <div className="dch-person-card">
      <img src={props.data?.picture} alt="image not loaded" className="pc-image"/>
      <div className="pc-content">
        <h3 className="pc-heading">
          {props.data?.name}
        </h3>
        <div className="pc-divider"/>
        <h4 className="pc-sub-heading">
          {props.data?.designation}
        </h4>
      </div>
    </div>
  );
};

export default PersonCard;