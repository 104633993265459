import React from 'react';

import './NoPage.scss';

const NoPage = () => {
  return (
    <section className="no-page">
      404 Page not found
    </section>
  );
};

export default NoPage;