import React from 'react';
import CoreServicesPageBanner from './CoreServicesPageBanner/CoreServicesPageBanner';
import CoreServicesTabs from './CoreServicesTabs/CoreServicesTabs';

const CoreServicesPage = () => {
  return (
    <>
      <CoreServicesPageBanner/>
      <CoreServicesTabs/>
    </>
  );
};

export default CoreServicesPage;