import React from 'react';

import './HomePageFeaturedDevelopmentsSection.scss';

const HomePageFeaturedDevelopmentsSection = () => {

  const content = {
    HP_FEATURED_DEV_HEADING: 'Featured Development',
    HP_FEATURED_DEV_CAROUSEL_CONTENT: [
      {
        title: 'VIDA',
        images: [
          'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_BUILDING_dx7psr.jpg'
        ],
      },
      {
        title: '',
        images: [
          'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934129/dch/3VIDA-Unit-03_Living_Room_Day_Cam2_Final_hrmjd5.png'
        ],
      },
      {
        title: 'VIDA LIVING ROOM',
        images: [
          'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934132/dch/4VIDA-Unit-03_Living_Room_Nigth_Cam1_Final_vlsbxk.png'
        ],
      },
      {
        title: '',
        images: [
          'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg'
        ],
      },
      {
        title: 'VIDA ROOFTOP',
        images: [
          'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934664/dch/VIDA_RESIDENCES_ROOF_TOP1_xaneej.jpg'
        ],
      },
      {
        title: '',
        images: [
          'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_ROOF_TOP2_dw2zxt.jpg'
        ],
      },
    ],
  }

  const CarouselCard = (cardProps: any) => {
    return (
      <div className="images-slideshow">
        <img src={cardProps.images[0]} alt="image not found"/>
        <img src={cardProps.images[0]} alt="image not found"/>
        <img src={cardProps.images[0]} alt="image not found"/>
        <div className="image-slideshow-overlay">
          <h2>{cardProps.title}</h2>
        </div>
      </div>
    )
  }

  return (
    <section className="home-page-featured-developments-section">
      <div className="container">
        <h2 className="section-heading">{content.HP_FEATURED_DEV_HEADING}</h2>
        <div className="carousel-grid">
          {content.HP_FEATURED_DEV_CAROUSEL_CONTENT.map((cardProps, i) =>
            <CarouselCard key={i} {...cardProps}/>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomePageFeaturedDevelopmentsSection;