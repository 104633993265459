import React, {useEffect, useState} from 'react';
import {TabPanel, TabView} from 'primereact/tabview';
import AppConst from '../../../App.const';

import './CoreServicesTabs.scss';
import Banner from '../../../Components/Banner/Banner';
import {Accordion, AccordionTab} from 'primereact/accordion';

const CoreServicesTabs = () => {
  const isDesktop = () => {
    console.log('AppConst.MIN_DESKTOP_WIDTH : ',AppConst.MIN_DESKTOP_WIDTH)
    console.log('window.innerWidth : ',window.innerWidth)
    console.log('window.innerWidth > AppConst.MIN_DESKTOP_WIDTH : ',window.innerWidth > AppConst.MIN_DESKTOP_WIDTH)
    return window.innerWidth > AppConst.MIN_DESKTOP_WIDTH;
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      setDesktop(isDesktop());
    });
  }, []);

  const [desktop, setDesktop] = useState(isDesktop());

  const panels = [
    {
      header: 'Advisory',
      index: '1',
      panel: {
        render: <div>panel data 1</div>,
        caption: 'ADVISORY',
        heading: 'Data-Driven Planning',
        description: 'MLA Advisory is an authority on the Canadian real estate market and project implementation. Our dedicated Advisory team maintains an exhaustive understanding of market intelligence. Trusted expertise and knowledge, combined with our deep comprehension of buyer demographics, produce effective and strategic solutions for every individual market context and opportunity. The objective is always the same – create lasting value and, ultimately, results. The expert MLA Advisory team approaches each project with pragmatism and data-driven insight.',
        infoColumns: [
          {
            columnHeader: 'MARKET INTELLIGENCE',
            columnDescription: 'Confidently understanding the environment you operate in places your development at an advantage. We undertake an in-depth investigation of external data, such as market trends, absorptions, and pricing. Our exploration spans from macro and micro trends and market transaction values to a full competitive absorption summary and demand analysis.',
          },
          {
            columnHeader: 'PRODUCT DESIGN',
            columnDescription: 'Creating a product that will appeal to the marketplace rests on the alignment of several variables. Once an optimal location has been selected, we begin a successful product design initiative by tapping into our market intelligence and defining the target market. This in turn is the foundation which will influence product types, unit mix planning, designing for utility, livability, overall aesthetic, and sales results.',
          },
          {
            columnHeader: 'PRICE ANALYSIS',
            columnDescription: 'A strategic pricing analysis is conducted by drawing from comparables and evaluating market forecasts and complex proforma statements. Our goal is to compose a multi-faceted pricing position that will deliver tremendous value and results, maximizing our clients’ objectives including revenue and absorptions. MLA Advisory’s analytical aptitude will uncover solutions to any pricing challenge.',
          },
        ],
        quote: 'Pennyfarthing Homes is committed to quality, value, and service excellence, and we expect the same from our partners. We continue to be impressed by MLA Canada’s market intelligence, collaborative approach, results-focused solutions, and outstanding project management.',
        narrator: 'Anthony Hepworth',
        narratorAdditional: 'PENNYFARTHING HOMES',
        bannerHeadingAdditional: <></>,
        bannerHeading: '',
        bannerDescription: 'While MLA Advisory informs and supports the development lifecycle, we also offer the Development Industry independent market intelligence and product design services. Should you require an analysis of a specific market to make an acquisition decision or seek project design and unit mix planning, we have the flexibility to provide this individual knowledge.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION ADVISORY@MLACANADA.COM',
        button: 'project marketing',
        link: '',
      }
    },
    {
      header: 'Project Marketing',
      index: '2',
      panel: {
        render: <></>,
        caption: 'PROJECT MARKETING',
        heading: 'Creating a Project’s Identity’s Legacy',
        description: 'MLA Canada oversees tailored real estate project marketing as part of our customized innovative strategy for sales. Our industry-leading team collaborates to produce programs that are detailed, proactive, clever, and creative. We build key messages that are true to each project brand, while championing the principles of each developer. Following a project’s launch, detailed metrics and reporting throughout the campaign keep clients informed and ensure alignment, while placing client objectives and brand identity first.',
        infoColumns: [
          {
            columnHeader: 'BRANDING & POSITIONING',
            columnDescription: 'We capture the essence of a project and the spirit of a neighbourhood in the creation of a cohesive brand. Beyond high design and communications, we develop a powerful narrative and marketing toolset to present a consistent identity – an identity that supports our sales teams and provides homebuyers with straightforward information they can connect with.',
          },
          {
            columnHeader: 'LAUNCH STRATEGY',
            columnDescription: 'Using the newly shaped brand identity and positioning approach, we formulate an eloquent and logical strategy to best introduce your project to the market. In collaboration with MLA Communications and our sales teams, a layered blueprint outlines timing, target market, media buying strategies and the marketing program as it pertains to prospecting and launching the sales program.',
          },
          {
            columnHeader: 'MARKETING EXECUTION',
            columnDescription: 'The sheer volume of projects the MLA Canada team has collectively brought to market has led to a deep understanding of what is required for successful marketing execution. A strategy is choreographed with input from our in-house marketing, communications, sales teams, and media buyer to ensure the brand narrative is flawlessly conveyed to the market. The immeasurable details making up each campaign are successfully managed on time and on budget.',
          },
        ],
        quote: 'Our communities receive tremendous value from MLA Canada’s collaborative approach, particularly around positioning, product design, and marketing and sales strategies. As our ambassador, the sales force is second-to-none, with extensive training and product knowledge unseen in the market.',
        narrator: 'Jason Turcotte',
        narratorAdditional: 'CRESSEY DEVELOPMENT GROUP',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Understanding Project Marketing',
        bannerDescription: 'With a tailored project marketing strategy, your development will realize success. Our team can guide you through the project marketing process to help you comprehend the Branding and Positioning, Launch Strategy, and Marketing Execution phases, and how they will pertain to your individual project.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION PROJECTMARKETING@MLACANADA.COM',
        button: 'communications',
        link: '',
      }
    },
    {
      header: 'Communications',
      index: '3',
      panel: {
        render: <></>,
        caption: 'Communications',
        heading: 'Articulating a Powerful Dialogue',
        description: 'Our creative MLA Communications team with expertise in digital media strategies and event planning, offers comprehensive solutions to connect people with homes. Compelling conversations are initiated and sustained through a variety of mediums. Multi-channel engagement across our cohesive platform allows us to identify opportunities at early stages of planning, ensuring a consistent message and brand identity is maintained. Clients receive actionable data reports in real time through every project phase.',
        infoColumns: [
          {
            columnHeader: 'MEDIA PLANNING & BUYING',
            columnDescription: 'Our in-house media team helps you find your target audience and speak to them at the right place and time using traditional to digital channels. Full media services include research, media planning, buying, and reporting. Assessment of project needs, the latest media trends and the habits of the target audience is completed to develop a strategic media campaign.',
          },
          {
            columnHeader: 'EVENT PLANNING',
            columnDescription: 'Our event planning team converts the project’s narrative into experiential form. Events are meticulously designed to engage homebuyers, realtors, and media. Key components to a project’s lifecycle include media previews, Presentation Centre launch, and ground-breaking ceremonies. It is crucial to a campaign’s success to create a memorable experience. ',
          },
        ],
        quote: 'Our projects have been successful because MLA Canada worked with us to create the approaches and execution that set us apart in our markets. I would recommend the company as a strategic partner without hesitation or reservation.',
        narrator: 'Anthony S. Lanni',
        narratorAdditional: 'BENTALL KENNEDY',
        bannerHeadingAdditional: <></>,
        bannerHeading: '',
        bannerDescription: 'MLA Communications works in collaboration with teams throughout the development lifecycle, while also acting as an independent expert on public relations, social media, and event planning for the Development Industry. If your requirements are limited to assistance with a public relations or social media campaign for your company, rather than a project, we can provide you with that specific expertise.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION COMMUNICATIONS@MLACANADA.COM',
        button: 'SALES',
        link: '',
      }
    },
    {
      header: 'Sales',
      index: '4',
      panel: {
        render: <></>,
        caption: 'SALES',
        heading: 'Advancing Your Success',
        description: 'MLA Canada is Canada’s most robust and intelligent sales force – an accomplishment built on our commitment to rigorous training. Our highly-specialized team formulates a clear and logical sales or rental lease-up strategy based on an in-depth understanding of MLA Advisory’s pricing analysis, MLA Communications’ messaging, the product offering, and the marketing campaign. Using advanced sales techniques and exhaustive product training, our intention is to speak to the target market and provide information, champion relationships, and tirelessly engage in follow-ups, resulting in high yield sales. We take great pride in meeting client objectives and exceeding expectations, while nurturing the elevated calibre of our sales team.',
        infoColumns: [
          {
            columnHeader: 'SALES STRATEGY',
            columnDescription: 'Our sales strategies are uniquely tailored for every client and market, leveraging our relationships and experience. They are detailed, yet adaptive, and we know exactly what to execute when to maximize results. Employing data from MLA Advisory and the outcomes of our proprietary Brand and Positioning session, we compose a structured, custom strategy aligned with the brand identity, location, and buyer demographics.',
          },
          {
            columnHeader: 'SALES TRAINING',
            columnDescription: 'Our finely-tuned sales team is exclusive to MLA Canada, promising dedication and integrity to our clients. Each sales professional engages in a thorough training program over several days and weeks unique to MLA Canada. Depth of experience is broadened with continuous education and emboldening management so our team may confidently uncover sales opportunities.',
          },
          {
            columnHeader: 'SALES MANAGEMENT',
            columnDescription: 'We curate the optimal team for your specific project, and keep the team focused, motivated, and energized throughout the project’s sales cycle to maximize results. We foster relationships with prospects and realtors, providing profound knowledge and attentive professionalism. Inventory management, strategy adjustments, correspondence, and administration are conducted competently to emphasize solutions and eradicate challenges.',
          },
        ],
        quote: 'We greatly enjoy our working relationship with MLA Canada. They are always prepared and proactive, bringing new ideas to the table and executing at a high level. Strategies are well-conceived and always with our interests and objectives in mind.',
        narrator: 'Chris Colbeck',
        narratorAdditional: 'TOWNLINE',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Introducing the MLA Sales Force',
        bannerDescription: 'Our unique and advanced sales systems will drive your project towards the results you aim for. From Sales Strategy to Sales Management, our highly-trained team is here to inform the progression. We are happy to enlighten you with more details of each element of our Sales Force\'s process, and how it connects with the development lifecycle.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION SERVICES@MLACANADA.COM',
        button: '',
        link: '',
      }
    },
    {
      header: 'Lease Up',
      index: '5',
      panel: {
        render: <></>,
        caption: 'LEASE UP',
        heading: 'Accelerated Results',
        description: 'We skillfully apply our extensive knowledge and experience in market sales to lease-up projects. For over fifteen years, we have honed our expertise in the lease-up arena, resulting in a track record of quickly and successfully stabilizing purpose-built rental assets. Like market sales, we utilize the specialized multi-family residential experiences of MLA. Our comprehensive services guide our clients through the lease-up process, from project envisioning to completion, with clarity and confidence, offering exceptional client services and unparalleled results.',
        infoColumns: [
          {
            columnHeader: 'ANALYTICS & PRODUCT DEVELOPMENT',
            columnDescription: 'An accurate understanding and interpretation of market conditions are essential to a successful completion. Our in-house MLA Advisory team is fluent in analyzing the lease-up trends and market intelligence, and collaborating with architects and interior designers to provide thoughtful guidance. We offer strategic recommendations to maximize results and mitigate risks for all MLA clients.',
          },
          {
            columnHeader: 'MARKETING STRATEGY & EXECUTION',
            columnDescription: 'MLA Canada’s skills in branding, positioning, and marketing generate outstanding lease-up outcomes. We understand how to best position the product based on what is important to renters to generate interest for conversion into a stable rental asset and maximum rental revenue. An essential part of the process is our proprietary detailed strategic planning process, where we recommend an ideal strategy, including effective communication to the marketplace, to maximize revenues and absorptions while being prudent with marketing expenditures.',
          },
          {
            columnHeader: 'LEASE-UP STRATEGY & MANAGEMENT',
            columnDescription: 'We continually recruit, train, and manage an experienced and exceptional team of leasing representatives, who are proficient and successful in processing prospects, closing transactions, and offering an incomparable experience. We develop and manage effective strategies to ensure timely stabilization and strong rental revenue. From tenant registration to application and tenancy agreement, our dedicated team ensures a smooth and professional administration process for both clients and tenants.',
          },
        ],
        quote: 'Our communities receive tremendous value from MLA Canada’s collaborative approach, particularly around positioning, product design, and marketing and sales strategies. As our ambassador, the sales force is second-to-none, with extensive training and product knowledge unseen in the market.',
        narrator: 'Jason Turcotte',
        narratorAdditional: 'CRESSEY DEVELOPMENT GROUP',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Experienced Lease-Up Services',
        bannerDescription: 'Our approach to realizing a successful lease-up campaign is nothing short of exceptional. MLA Canada’s comprehensive experience and knowledge are employed to maximize revenue and stabilize tenancy. We encourage you to contact us with any lease-up inquiries – we would be happy to discuss our strategy and how it may benefit your unique project.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION LEASEUP@MLACANADA.COM',
        button: 'COMPLETE',
        link: '',
      }
    },
    {
      header: 'Complete',
      index: '6',
      panel: {
        render: <></>,
        caption: 'COMPLETE',
        heading: 'Top-Tier Homebuyer Experiences',
        description: 'Recognizing our role as a developer representative, we take great pride in providing exceptional homebuyer experiences for individuals looking to purchase a home. We attribute our Customer Care success to our responsiveness and our personalized professional approach to every homebuyer journey. From completing contracts to managing and coordinating amendments to communicating construction timelines, our helpful team creates an environment where homeowners feel informed and comfortable with their purchase.\n' +
          '\n' +
          'MLA Complete is our platform of customer-focused resources engineered to simplify the completion process. A compendium of best-in-class partnerships, MLA Complete includes services for mortgages, legal, insurance, property management, and moving.',
        infoColumns: [
          {
            columnHeader: 'CONTRACT MANAGEMENT',
            columnDescription: 'Our team engages innovative systems, such as Avesdo, for contract management, eliminating the need for paper and making the process sustainable and convenient for all parties. Ensuring contracts and correspondence are accurate, complete, and properly executed is critical, particularly in an ever-changing market – our team is always on-point. Intensive and detailed reporting is also conducted with clarity and timeliness.',
          },
          {
            columnHeader: 'CUSTOMER EXPERIENCE',
            columnDescription: 'Acknowledging the value of homeowner feedback, we encourage the two-way flow of information with each interaction. Every point of contact is a positive experience, with timely and effective customer communication throughout the process. We are happy to offer language assistance, when necessary. Our approach is empathetic and caring as we speak of the developer’s brand in the highest regard.',
          },
          {
            columnHeader: 'COMPLETION SERVICES',
            columnDescription: 'A successful transaction relies on professionalism and efficiency. Our Sales Force works together to streamline the process, crafting a smooth experience for the homeowner. Documents are prepared with 100% accuracy, due diligence is carried out with precision, and our demeanor is always confident, upbeat and friendly. ',
          },
        ],
        quote: 'Working with MLA Canada has proven to be a great partnership with outstanding sales results. They understand who we are as a developer, and share our core values and passion for excellence.',
        narrator: 'Karen West',
        narratorAdditional: 'BOFFO DEVELOPMENTS LTD.',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Defining Better Customer Care',
        bannerDescription: 'To better comprehend the importance of crafting a positive experience, we can explain the merits of our personalized Customer Care phase and how it will elevate your project to success. This final segment of the development lifecycle is an essential element in leaving a lasting impression.',
        bannerDescriptionFooter: 'CONTACT US FOR MORE INFORMATION CUSTOMERCARE@MLACANADA.COM',
        button: 'DCH OPEN SPACE',
        link: '',
      }
    },
    {
      header: 'DCH OPEN SPACE',
      index: '7',
      panel: {
        render: <></>,
        caption: '',
        heading: 'All-In-One Sales Gallery Space',
        description: 'MLA Open Space is a new open concept Sales Gallery Space in the Fraser Valley to showcase projects and house sales teams all under one roof. Accessible and central, Open Space is a modern and welcoming backdrop for homebuyers to begin experiencing your sales program within weeks.',
        infoColumns: [
          {
            columnHeader: 'TIME AND MONEY',
            columnDescription: 'MLA Open Space is a blank slate for establishing an impressive cost-effective customer experience. Save time and money by simply building your kitchen, adding graphics and fixtures such as a scale model and colour boards, programming our touchscreens and unveiling your project.',
          },
          {
            columnHeader: 'CUSTOMER EXPERIENCE',
            columnDescription: 'Our Presentation Centre is conveniently located within Willoughby Town Centre, with parking available and walkable amenities. The Sales Gallery space is accessed from our bright main reception area which, in combination with Open Space, offers an ideal setting for Grand Opening events.',
          },
          {
            columnHeader: 'FLOORPLAN',
            columnDescription: 'The Sales Gallery Space is designed to incorporate the latest tools to sell homes including a kitchen vignette area, closing office, space for an architectural scale model or site plan and touchscreens.',
          },
        ],
        quote: 'We approached MLA for a cost-effective venue to sell our homes prior to completion of the first phase of Verge. The MLA Presentation Centre was the perfect solution.  Within four weeks, the Verge Presentation Centre was built and merchandised with construction costs equaling the cost of building a kitchen.',
        narrator: '',
        narratorAdditional: 'INDUSTRY LEADER',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Unique Venue',
        bannerDescription: 'Reach out to see how this unique venue can serve as the launching pad for your sales program. We have prioritized flexibility and versatility in creating this exceptional space and we look forward to spotlighting your next project.',
        bannerDescriptionFooter: '',
        button: 'MLA Oi',
        link: '',
      }
    },
    {
      header: 'MLA Oi',
      index: '8',
      panel: {
        render: <></>,
        caption: 'MLA Oi',
        heading: 'Actionable Intelligence',
        description: 'Oi is an Agile Data Ecosystem of digital tools working together to drive stronger sales programs. We’ve amassed a vast repository of aggregate intelligence, fed by a continual influx of project data points. Implementing Oi technology to map out your sales program gives you a 360 view of the marketplace before you break ground. Powerful ERP technology tracks a multi-channel system of customer engagement with interactive tools MLA Live, Home Store, Sales Floor and Homeowner Portal all in one efficient platform.',
        infoColumns: [
          {
            columnHeader: 'DEEPER AND ON-DEMAND INSIGHTS',
            columnDescription: 'Oi gives clients unprecedented visibility into the customer journey. Our Lead Score algorithm comprised of 63 key indicators focuses resources on qualified buyers. The solution tracks homebuyer interactions across web, social, and in person so that strategic decisions can be made in real time to drive sales.',
          },
          {
            columnHeader: 'BETTER HOMEBUYER EXPERIENCES',
            columnDescription: 'Marketing that runs 24-7 means customer engagement not limited to the opening hours of your Sales Gallery. The Oi platform allows homebuyers to select and customize homes when it suits their schedule. In turn, key insights facilitate a tailored and transparent homebuying experience beyond completion.',
          },
          {
            columnHeader: 'IMPROVED REVENUE MANAGEMENT',
            columnDescription: 'Oi delivers a panoramic view of the project landscape with the ability to zoom in and attribute a through line form social ad spending to completed sale. Expect integrated inventory and strategic sales management, targeted ad spending, shortened sales cycles and increased absorption rates.',
          },
        ],
        quote: 'MLA Oi has given us greater visibility when it comes to managing our project. We’re able to utilize our data at any time and pivot the direction of marketing accordingly, all while saving time and valuable resources.',
        narrator: '',
        narratorAdditional: 'INDUSTRY LEADER',
        bannerHeadingAdditional: <></>,
        bannerHeading: 'Track Every Touchpoint in Real Time',
        bannerDescription: 'With an entire division dedicated to web-based technology, MLA engineered MLA Oi to provide developers with unprecedented project reach and transparency at every phase of the development lifecycle. We are here to step you through onboarding and show you how we track every touchpoint in real time to accelerate sales.',
        bannerDescriptionFooter: '',
        button: '',
        link: '',
      }
    },
  ];

  const HeaderTemplate = (options: any, index: string, header: string) => {
    return (
      <div className="csp-tabs-header-template" onClick={options.onClick}>
        <label className="header-index">{index}</label>
        <label className="header-label">{header}</label>
      </div>
    );
  }

  const PanelTemplate = (panel: any) => {
    const data = panel.data;
    return (
      <div className="csp-tab-panel">
        <h2 className="csp-tp-caption">{data.caption}</h2>
        <h3 className="csp-tp-heading">{data.heading}</h3>
        <p className="csp-tp-paragraph">{data.description}</p>

        <div className="csp-tp-columns">
          {
            data?.infoColumns?.map((info: any, index: number) => (
              <div className="csp-tp-column" key={index}>
                <h4 className="csp-tp-column-heading">{info.columnHeader}</h4>
                <h4 className="csp-tp-column-paragraph">{info.columnDescription}</h4>
              </div>
            ))
          }
        </div>

        <div className="csp-tp-banner-row">
          <div className="csp-tp-quote">
            <img className="quote-icon" src={AppConst.QUOTE_ICON_IMG_SRC} alt="image not found"/>
            <p className="quote">{data.quote}</p>
            <img className="quote-icon upside-down" src={AppConst.QUOTE_ICON_IMG_SRC} alt="image not found"/>
            <p className="narrator">{data.narrator}</p>
            <p className="narrator-additional">{data.narratorAdditional}</p>
          </div>
          <div className="csp-tp-banner">
            <Banner img={AppConst.CORE_SERVICES_BANNER_IMG_SRC}>
              <div className="csp-tp-banner-content">
                {data.bannerHeadingAdditional}
                <h6 hidden={(data.bannerHeading === '')}>{data.bannerHeading}</h6>
                <p>{data.bannerDescription}</p>
                <label>{data.bannerDescriptionFooter}</label>
              </div>
            </Banner>
          </div>
        </div>

        <div className="csp-tp-button-row" hidden={data.button === ''}>
          <a>{data.button}</a>
        </div>
        {data?.data?.render}
      </div>
    )
  }

  return (
    <section className="core-services-tabs">
      {
        desktop ? (
          <TabView>
            {
              panels.map((data, index) => (
                <TabPanel headerTemplate={(options => HeaderTemplate(options, data?.index, data?.header))} key={index}>
                  <PanelTemplate data={data?.panel}/>
                </TabPanel>
              ))
            }
          </TabView>
        ) : (
          <Accordion activeIndex={0}>
            {
              panels.map((data, index) => (
                <AccordionTab header={data?.header} key={index}>
                  <PanelTemplate data={data?.panel}/>
                </AccordionTab>
              ))
            }
          </Accordion>
        )
      }
    </section>
  );
};

export default CoreServicesTabs;