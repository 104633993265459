import React, {useEffect, useState} from 'react';

import NavigationTabs from "./NavigationTabs";
import {Button} from "primereact/button";
import AppConst from "../../App.const";

import './Navbar.scss';

const Navbar = () => {

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 50);
        });
    }, []);
    const [scroll, setScroll] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleOpenMenu() {
        setMenuOpen(!menuOpen);
    }

    return (
        <React.Fragment>
            <nav className={`navbar ${scroll ? 'shrink' : ''}`}>
                <div className="nav-container">
                    <div className="logo-container">
                        {/*<h1 className="logo-text">BUENO PROPERTIES</h1>*/}
                        {/*<DchMiamiLogo/>*/}
                        {/*<img src={AppConst.WHITE_LOGO_IMG_SRC} alt="logo" className="logo white-logo"/>*/}
                        <img src={AppConst.BLACK_LOGO_IMG_SRC} alt="logo" className="logo black-logo"/>
                    </div>
                    <div className={`navbar-tab-container ${menuOpen ? 'visible' : ''}`}>
                        <NavigationTabs/>
                    </div>
                    <div className="button-container">
                        <Button icon={`pi ${menuOpen? 'pi-times' :'pi-bars'}`}
                                className="p-button-rounded p-button-text btn-format"
                                aria-label="Submit"
                                onClick={() => toggleOpenMenu()}/>
                    </div>

                    {/*<div className="popup-tabs">*/}
                    {/*    <NavigationTabs/>*/}
                    {/*</div>*/}
                </div>
            </nav>
            <div className="navbar-white-space"/>
        </React.Fragment>
    );
}

export default Navbar;