import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ProjectFactHeroSection from './ProjectFactHeroSection/ProjectFactHeroSection';
import ProjectFactDescriptionSection from './ProjectFactDescriptionSection/ProjectFactDescriptionSection';
import AppConst from '../../../App.const';
import AppStatics from '../../../App.statics';

const ProjectFactViewPage = () => {
  const {id} = useParams();

  useEffect(() => {
    fetch(`${AppConst.DATA_PATH_PROJECT_VIEW}/${id}.${AppStatics.getLanguage()}.json`)
      .then((r) => r.json())
      .then((data) =>{
        setData(data)
      });
  }, [])

  const [data, setData] = useState({
    city: '',
    name: '',
    img_src: [
      AppConst.HOME_BANNER_SECTION_ONE_IMG_SRC,
    ],
    contentHeading: '',
    contentParagraph: '',
    features: {
      homes: '',
      bedrooms: '',
      numberOfHomes: '',
      developer: '',
      status: '',
    }
  });

  return (
    <>
      <ProjectFactHeroSection data={data}/>
      <ProjectFactDescriptionSection data={data}/>
    </>
  );
};

export default ProjectFactViewPage;