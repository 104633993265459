import React, {useState} from 'react';
import FilterTab from './FilterTab';

import './FilterTabs.Component.scss';

const FilterTabsComponent = (props: any) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpand = () => {
    setExpanded(!expanded);
  }

  return (
    <div className="filter-tabs-component">
      {
        props.filterTabs.map((filterTab: any, index: any) => (
          <FilterTab data={filterTab} key={index} expanded={expanded} onToggleExpand={() => toggleExpand()}/>
        ))
      }
      <div className="filter-tab">
        <h5 className="tab-title reset">Reset</h5>
      </div>
    </div>
  );
};

export default FilterTabsComponent;