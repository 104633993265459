import React, {useEffect, useState} from 'react';
import FeaturedProjectsSection from './FeaturedProjectsSection/FeaturedProjectsSection';
import CurrentProjectsSection from './CurrentProjectsSection/CurrentProjectsSection';
import PreviousProjectsSection from './PreviousProjectsSection/PreviousProjectsSection';
import AppConst from '../../App.const';
import AppStatics from '../../App.statics';

const ProjectsPage = () => {

  const [projectsData, setProjectsData] = useState({featuredProjects:[]});

  useEffect(() => {
    fetch(`${AppConst.DATA_PATH_PROJECTS}/${AppConst.FILE_NAME_PROJECTS_DATA_FILE}.${AppStatics.getLanguage()}.json`)
      .then((r) => r.json())
      .then((data) =>{
        setProjectsData(data)
      });
  }, [])

  return (
    <>
      <FeaturedProjectsSection cardsData={projectsData?.featuredProjects}/>
      <CurrentProjectsSection/>
      <PreviousProjectsSection/>
    </>
  );
};

export default ProjectsPage;