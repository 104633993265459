import React from 'react';

const FilterTab = (props: any) => {

  return (
    <div className={`filter-tab ${props.expanded ? 'expanded': ''}`}>
      <h5 className="tab-title" onClick={() => props.onToggleExpand()}>{props.data.title}</h5>
      <div className="tab-filters">
        {props.data.filtersList.map((data: any, index: number) => (
          <button key={index} >{data}</button>
        ))}
      </div>
    </div>
  );
};

export default FilterTab;