import React, {useState} from 'react';
import {Dropdown, DropdownChangeParams} from 'primereact/dropdown';
import AppConst from '../App.const';

const LanguagePicker = (props: any) => {
  const [lang, setLang] = useState(AppConst.LANG_CODE_EN)

  const languages = [
    { name: AppConst.LANG_NAME_EN, code: AppConst.LANG_CODE_EN },
    { name: AppConst.LANG_NAME_ES, code: AppConst.LANG_CODE_ES },
    { name: AppConst.LANG_NAME_PT, code: AppConst.LANG_CODE_PT },
    { name: AppConst.LANG_NAME_PL, code: AppConst.LANG_CODE_PL },
    { name: AppConst.LANG_NAME_DE, code: AppConst.LANG_CODE_DE },
    { name: AppConst.LANG_NAME_FR, code: AppConst.LANG_CODE_FR },
    { name: AppConst.LANG_NAME_IT, code: AppConst.LANG_CODE_IT },
    { name: AppConst.LANG_NAME_PT_BR, code: AppConst.LANG_CODE_PT_BR },
  ];

  const onChange = (lang: DropdownChangeParams) => {
    setLang(lang.value)
    props.onChange(lang.value)
  }

  return (
    <>
      <Dropdown value={lang} options={languages}
                onChange={(lang) => onChange(lang)}
                optionLabel="Language"
                placeholder="Select The language"/>
    </>
  );
};

export default LanguagePicker;