import React from 'react';

import './Banner.scss';

const Banner = (props: any) => {
  return (
    <div className="banner">
      <img className="banner-background-image" src={props.img} alt="banner"/>
      <div className="banner-content" style={{background: props.background ? props.background : '#00000095'}}>
        {props.children}
      </div>
    </div>
  );
};

export default Banner;