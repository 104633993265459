import React from 'react';
import { useNavigate } from 'react-router-dom';

import './ProjectCard.scss';
import AppConst from '../../App.const';

const ProjectCard = (props: any) => {
  let navigate = useNavigate();

  const goToLink = () => {
    navigate(`/${AppConst.PATH_PROJECTS}/${AppConst.SUB_PATH_PROJECTS_PROJECT}/${props.renderData?.linkTo}`);
  }

  return (
    <div className={`dch-project-card ${props.dark ? 'pc-dark' : ''}`} onClick={() => goToLink()}>
      <img src={props.renderData?.imgSrc} alt="image not loaded" className="pc-image"/>
      <div className="pc-content">
        <h4 className="pc-sub-heading">
          {props.renderData?.subHeading}
        </h4>
        <h3 className="pc-heading">
          {props.renderData?.heading}
        </h3>
        <div className="pc-divider"/>
        <label className="pc-action">
          {props.renderData?.action}
        </label>
      </div>
      <div className="pc-details-button-container" hidden={!props.renderData?.detailsButton}>
        <button className="pc-details-button">
          {props.renderData?.detailsButton}
        </button>
      </div>
    </div>
  );
};

export default ProjectCard;