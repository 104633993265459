import React from 'react';
import PropTypes from 'prop-types';

import './Footer.scss';
import AnimatedLogo from '../AnimatedLogo/AnimatedLogo';
import NavigationTabs from '../Navbar/NavigationTabs';
import {Button} from 'primereact/button';
import AppConst from '../../App.const';

Footer.propTypes = {};

function Footer(props: any) {
  return (
    <footer className="footer">
      <div className="container">
        <hr/>
        <div className="footer-content">
          <div className="footer-column">
            <NavigationTabs/>
          </div>
          <div className="footer-column">
            <label>MAIN OFFICE</label>
            <label>3050 Biscayne Blvd suite 504</label>
            <label>Miami Fl 33137</label>
            <label>USA</label>
            <br/>
            <label>-</label>
            <label></label>
            <label></label>
            <label></label>
          </div>
          <div className="footer-column">
            <label>Miami Office</label>
            <label>2125 Biscayne Blvd #322,</label>
            <label>Miami, FL 33137, USA</label>
            <label>Tlf. (786) 796-1433 / (786) 822-6311</label>
            <label>-</label>
            <label>Houston Office</label>
            <label>2323 s voss rd suite 150-C</label>
            <label>Houston</label>
            <label>Tx 77057 Tlf.</label>
            <label>(713) 405-3744</label>
            <label>(713) 405-3726</label>
            <label>(888) 598-0411</label>
          </div>
          <div className="footer-column">
            <div className="flex social-media">
              <i className="pi pi-linkedin"/>
              <i className="pi pi-instagram"/>
              <i className="pi pi-twitter"/>
              <i className="pi pi-facebook"/>
              <i className="pi pi-youtube"/>
              <i className="pi pi-linkedin"/>
            </div>
            <label>DCH MIAMI NEWSWIRE</label>
            <p className="footer-description">Subscribe to the DCH MIAMI Newswire and become real estate intelligent with the latest market news,
              development and design updates, and presale information delivered right to your inbox 5 days a week.</p>

            <a className="subscribe">subscribe now</a>
            <img src={AppConst.BLACK_LOGO_IMG_SRC} alt="logo" className="footer-logo"/>
            <label className="small-text">© 2021 DCH MIAMI | Privacy Policy</label>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;