import React from 'react';

import './PreviousProjectsSection.scss';
import ProjectCard from '../../../Components/ProjectCard/ProjectCard';
import FilterTabsComponent from '../FilterTabsComponent/FilterTabs.Component';

const PreviousProjectsSection = () => {

  const content = {
    PP_FPS_HEADING: 'previous projects',
    PP_FPS_CARDS_CONTENT: [
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
      {
        imgSrc: 'https://res.cloudinary.com/dahosa0zw/image/upload/v1664934126/dch/VIDA_RESIDENCES_FRONT_gjkcex.jpg',
        subHeading: 'miami, fl',
        heading: 'vida',
        action: 'register now',
      },
    ],
  }

  return (
    <section className="previous-projects-section">
      <div className="container">
        <h2 className="heading-three-sub">{content.PP_FPS_HEADING}</h2>
        <FilterTabsComponent filterTabs={[
          {
            title: 'STATUS',
            filtersList: ['Register Now', 'Coming Soon', 'Renting Now', 'Now Selling',],
          },
          {
            title: 'CITY',
            filtersList: ['West Vancouver', 'Penticton', 'Richmond', 'Vancouver', 'Surrey', 'Vancouver', 'Vancouver West', 'Coquitlam', 'Burnaby', 'North Vancouver', 'West Coquitlam', 'Victoria',],
          },
          {
            title: 'BUILDING TYPE',
            filtersList: ['Condos', 'Condos & Townhomes', 'Garden Homes & Townhomes', 'Townhomes', 'Condos + townhomes', 'Condos & Loft Homes',],
          },
          {
            title: 'PRODUCT TYPE',
            filtersList: ['Residential', 'Condos'],
          },
        ]}/>
        <div className="pp-cards-grid">
          {content.PP_FPS_CARDS_CONTENT.map((data, index) => (
            <ProjectCard renderData={data} key={index} dark/>
          ))}
        </div>
      </div>
    </section>
  );
};

export default PreviousProjectsSection;