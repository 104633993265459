import React from 'react';
import LifecycleCard from './LifecycleCard/LifecycleCard';

import './HomePageDevelopmentLifecycleSection.scss';

const HomePageDevelopmentLifecycleSection = () => {

  const content = {
    HP_DEV_LIFECYCLE_HEADING: 'Development Lifecycle',
    HP_LIFECYCLE_INTRO_HEADING: 'Efficient Marketing. Accelerated Sales.',
    HP_LIFECYCLE_INTRO: 'Building on decades of experience and partnerships across the industry, we oversee every ' +
      'stage of the development lifecycle. We strategize laterally across specialized teams, enabling seamless ' +
      'integration of Core Services for the projects we champion.',
    HP_LIFECYCLE_CARD_CONTENT: [
      {
        title: 'Acquisition',
        caption: 'Our intimate market knowledge and sophisticated investment analysis will inform your acquisition, ' +
          'driving you towards a successful result.',
        img: 'https://mlacanada.com/sites/default/files/styles/large/public/callout-image/shutterstock_430168801_BW.jpg?itok=NDtpw4uy',
      },
      {
        title: 'Development',
        caption: 'From supporting the development application process to influencing compelling design, we advance ' +
          'your development into a highly marketable product.',
        img: 'https://mlacanada.com/sites/default/files/styles/large/public/callout-image/bishop_int_ensuite_final2-2_BW.jpg?itok=vd_dZh5E',
      },
      {
        title: 'Marketing',
        caption: 'With a comprehensive understanding of buyers and the market, we design, orchestrate, and manage a ' +
          'marketing program tailored to your unique development.',
        img: 'https://mlacanada.com/sites/default/files/styles/large/public/callout-image/DSC_9958_CC_Web.jpg?itok=ERvY_O9O',
      },
      {
        title: 'Sales & Lease-Up',
        caption: 'Through extensive training and management programs, our world-class, multi-tiered sales approach ' +
          'has been honed to generate sales beyond expectation.',
        img: 'https://mlacanada.com/sites/default/files/styles/large/public/callout-image/Kings-Crossing-by-Cressey-Web-22_BW.jpg?itok=ghPxDeed',
      },
      {
        title: 'Construction',
        caption: 'As your vision unfolds into reality, we pro-actively manage sales and continue to work tirelessly ' +
          'through flawless collaboration with vendors and communication with homebuyers.',
        img: 'https://mlacanada.com/sites/default/files/styles/large/public/callout-image/shutterstock_112558970_BW2.jpg?itok=nUOVLo3W',
      },
      {
        title: 'Completion',
        caption: 'A smooth transaction strengthens the relationship between developer and buyer throughout the ' +
          'completion process, generating a positive experience and future brand equity.',
        img: 'https://mlacanada.com/sites/default/files/styles/large/public/callout-image/shutterstock_441586360_CC.jpg?itok=6v8v6hoy',
      },
    ],
  };

  return (
    <section className="home-page-development-lifecycle-section">
      <div className="container">
        <h2 className="section-heading">{content.HP_DEV_LIFECYCLE_HEADING}</h2>
        <h3 className="intro-heading">{content.HP_LIFECYCLE_INTRO_HEADING}</h3>
        <p className="intro">{content.HP_LIFECYCLE_INTRO}</p>
        <div className="lifestyle-card-grid">
          {content.HP_LIFECYCLE_CARD_CONTENT.map((cardProps, i) =>
            <LifecycleCard key={i} {...cardProps}/>
          )}
        </div>
      </div>
    </section>
  );
};

export default HomePageDevelopmentLifecycleSection;