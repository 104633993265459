import React, {Component} from 'react';
import AppConst from './App.const';

class AppStatics extends Component {
  public static currentTab = AppConst.PATH_HOME;

  static getLanguage(): string {
    return AppConst.LANG_CODE_EN;
  }
}

export default AppStatics;