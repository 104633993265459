import React from 'react';

import './HomePageHeroSection.scss';

import AppConst from '../../../App.const';

function HomePageHeroSection() {
  return (
    <div className="homepage-hero-section">
      {/*<video src={AppConst.HOME_HERO_SECTION_VID_SRC} autoPlay={true} loop*/}
      {/*  poster="https://res.cloudinary.com/dahosa0zw/image/upload/v1664934132/dch/4VIDA-Unit-03_Living_Room_Nigth_Cam1_Final_vlsbxk.png"/>*/}
      <iframe src="https://www.youtube.com/embed/efct6sIjEyw?start=8"
        title="YouTube video player" frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"/>
    </div>
  );
}

export default HomePageHeroSection;